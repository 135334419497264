<template>
  <BaseElSelect
    v-model="syncModel"
    v-loading="loading"
    :multiple="multiple"
    clearable
    collapse-tags
    no-data-text="暫無數據"
    placeholder="請選擇合約方案"
    @change="$emit('changeShop')"
  >
    <BaseElSelectOption
      v-for="item in contractPlanList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </BaseElSelect>
</template>

<script>
import { GetContractPlan, GetContractPlanCount } from '@/api/contract'
import { find } from 'lodash'

export default {
  name: 'ContractPlanSelect',
  props: ['model', 'multiple', 'mode', 'shopPlan'],
  data: () => ({
    contractPlanList: [],
    loading: false,
  }),

  computed: {
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },

    shop () {
      return this.$store.state.shop
    },
  },

  async mounted () {
    this.loading = true
    await this.getContractPlan()
    if (this.mode === 'update') {
      if (this.shopPlan) {
        const exist = find(this.contractPlanList, { id: this.shopPlan.id })
        if (!exist) {
          const plan = { ...this.shopPlan }
          plan.name = plan.name + ' (已失效)'
          this.contractPlanList.push(plan)
        }
      }
    }
    this.loading = false
  },

  methods: {
    async getContractPlan () {
      const max = await GetContractPlanCount({ name: undefined })
      let start = 0
      this.contractPlanList = await GetContractPlan({
        start,
        limit: 100,
      })

      while (this.contractPlanList.length < max) {
        start += 100
        const res = await GetContractPlan({
          start,
          limit: 100,
        })

        this.contractPlanList.push(res)
      }

      // this.contractPlanList.push({ name: '不選擇合約方案', id: 'none' })
    },
  },
}
</script>
